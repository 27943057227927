import { h, app } from "hyperapp"

// -- EFFECTS & SUBSCRIPTIONS --

const fetchJSONData = (dispatch, options) => {
  dispatch(options.onstart)
  fetch(options.url)
    .then(response => response.json())
    .then(data => dispatch(options.onresponse, data))
    .catch(() => dispatch(options.onresponse, {}))
    .finally(() => dispatch(options.onfinish))
}

const intervalSubscription = (dispatch, options) => {
  const interval = setInterval(() => dispatch(options.action), options.time)
  return () => clearInterval(interval)
}

// -- EFFECT CREATORS --

const storyLoader = searchWord => [
  fetchJSONData,
  {
    url: `./is.json`,
    onresponse: GotStories,
    onstart: [SetFetching, true],
    onfinish: [SetFetching, false],
  },
]

// -- ACTIONS --

const StartEditingFilter = state => ({ ...state, editingFilter: true })

const StopEditingFilter = state => [
  {
    ...state,
    editingFilter: false,
  },
  storyLoader(state.filter),
]

const SetFilter = (state, word) => ({ ...state, filter: word })

const SelectStory = (state, id) => ({
  ...state, // keep all state the same, except for the following:
  reading: id,
  stories: {
    ...state.stories, //keep stories the same, except for:
    [id]: {
      ...state.stories[id], //keep this story the same, except for:
      seen: true,
    },
  },
})

const GotStories = (state, stories) => ({
  ...state,

  // replace old stories with new,
  // but keep the 'seen' value if it exists
  stories: Object.keys(stories)
    .map(id => [
      id,
      {
        ...stories[id],
        seen: state.stories[id] && state.stories[id].seen,
      },
    ])
    .reduce((o, [id, story]) => ((o[id] = story), o), {}),

  // in case the current story is in the new list as well,
  // keep it selected, Otherwise select nothing
  reading: stories[state.reading] ? state.reading : null,
})

const SetFetching = (state, fetching) => ({ ...state, fetching })

const ToggleAutoUpdate = state => ({
  ...state,
  autoUpdate: !state.autoUpdate,
})

// -- VIEWS ---

const emphasize = (word, string) =>
  string.split(" ").map(x => {
    if (x.toLowerCase() === word.toLowerCase()) {
      return h("em", {}, x + " ")
    } else {
      return x + " "
    }
  })

const storyThumbnail = props =>
  h(
    "li",
    {
      onclick: [SelectStory, props.id],
      class:" bg-"+props.color,
    },
    [h("i", { class: "color icon fa " + props.icon}),
      h("p", { class: "name" }, emphasize(props.filter, props.name)),
      h("i",  {class: {
        "fa fa-toggle-on": props.reading,
        "fa fa-toggle-off": !props.reading,
      }},),
    ],
  )

const storyList = props =>
  h("div", { class: "menu-tasks" }, [
    // show spinner overlay if fetching
    props.fetching &&
      h("div", { class: "menu-tasks" }, [h("span", { class: "taskHead"} )]),

    h(
      "ul",
      {},
      Object.keys(props.stories).map(id =>
        storyThumbnail({
          id,
          name: props.stories[id].name,
          color: props.stories[id].color,
          unread: !props.stories[id].seen,
          reading: props.reading === id,
          filter: props.filter,
          icon: props.icon = props.stories[id].icon
        }),
      ),
    ),
  ])

const filterView = props =>
  h("div", { class: "filter" }, [
    "Filter:",

    props.editingFilter
      ? h("input", {
          type: "text",
          value: props.filter,
          oninput: [SetFilter, event => event.target.value], // <----
        })
      : h("span", { class: "filter-word" }, props.filter),

    props.editingFilter
      ? h("button", { onclick: StopEditingFilter }, "\u2713")
      : h("button", { onclick: StartEditingFilter }, "\u270E"),
  ])

const storyDetail = props =>
  h("div", { class: "story" }, [
    props && h("h1", {}, props.name),
    props &&
      h(
        "p",
        {},
        `Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, qui
                    nostrud exercitation ullamco laboris nisi ut aliquip
                    ex ea commodo consequat.`,
      ),
    props && h("p", { class: "signature" }, props.color),
  ])

const autoUpdateView = props =>
  h("div", { class: "autoupdate" }, [
    "Auto update: ",
    h("input", {
      type: "checkbox",
      checked: props.autoUpdate,
      oninput: ToggleAutoUpdate,
    }),
  ])

const container = content => h("div", { class: "container" }, content)

// -- RUN --
app({
  node: document.getElementById("app"),
  init: [
    {
      editingFilter: false,
      autoUpdate: false,
      filter: "#",
      reading: null,
      stories: {},
    },
    storyLoader("#"),
  ],
  view: state =>
    container([
      filterView(state),
      storyList(state),
      storyDetail(state.reading && state.stories[state.reading]),
      autoUpdateView(state),
    ]),
  subscriptions: state => [
    state.autoUpdate &&
      !state.editingFilter && [
        intervalSubscription,
        {
          time: 5000, //milliseconds,
          action: StopEditingFilter,
        },
      ],
  ],
})
